import React from "react"

const PrivacyPolicy = () => {
  return (
    <div className="text-black p-6">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>

      <section className="my-3">
        <h2>1. Introduction</h2>
        <p>
          VSN LLP (VSN) is committed to respecting our customers' privacy and
          protecting their personal information from misuse or unauthorised
          disclosure and complying with privacy laws. VSN values its reputation
          and aims to maintain high ethical standards in the conduct of our
          business affairs.
        </p>
        <p>
          This privacy policy explains how we use any personal information we
          collect about you when you use this website.
        </p>
      </section>

      <section className="my-3">
        <h2>2. Business Summary</h2>
        <p>
          VSN is a Professional Outsourcing Shared Services Centre, specialising
          in the business area of outsourcing Accountancy work.
        </p>
        <p>
          We process personal information to enable us to provide accounting,
          auditing and related services, to maintain our own accounts and
          records and to support and manage our employees and provide the
          following services:
        </p>
        <ul className='list-disc'>
          <li>VAT Filing</li>
          <li>Management accounts</li>
          <li>Bookkeeping</li>
          <li>
            Preparation and filing of annual accounts and corporation tax
            returns
          </li>
          <li>Preparation and filing of annual tax returns</li>
          <li>Company secretarial services</li>
          <li>Payroll processing including CIS, P11D, SSP and SMP</li>
          <li>Profit improvement consultancy</li>
          <li>Personal and business tax planning</li>
          <li>Business start-up advice</li>
        </ul>
      </section>

      <section className="my-3">
        <h2>3. Organisation Contact Details</h2>
        <h3>Data Controller Details</h3>
        <table>
          <tbody>
            <tr>
              <td>Email Address:</td>
              <td>admin@vsnsharedservices.com</td>
            </tr>
            <tr>
              <td>Telephone Number:</td>
              <td>+91- 9899783871</td>
            </tr>
            <tr>
              <td>Postal Address:</td>
              <td>
                Unit no. 511
                <br />
                Plot no. B-08, GDITL Tower
                <br />
                Netaji Subhash Place, Pitampura
                <br />
                New Delhi-110034
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Data Protection Officer Details</h3>
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>Vaibhav Jain</td>
            </tr>
            <tr>
              <td>Email Address:</td>
              <td>Vaibhav@VSNsharedservices.com</td>
            </tr>
            <tr>
              <td>Telephone Number:</td>
              <td>+91-9899783871</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="my-3">
        <h2>4. Data We Collect - Online Services</h2>
        <p>
          This Privacy Policy relates to our use of any personal information we
          collect from you via the following:
        </p>
        <ul className='list-disc'>
          <li>
            Our website's associated contact forms:{" "}
            <a href="https://vsnsharedservices.com/">
              https://vsnsharedservices.com/
            </a>
          </li>
          <li>
            From our contact email addresses: Email address:
            admin@vsnsharedservices.com
          </li>
        </ul>
        <p>
          Depending on which of our services you use, we may collect the
          following information:
        </p>
        <ul className='list-disc'>
          <li>
            Name, Email Address, Telephone Number, Postal Address, Business
            Name, Services you are interested in
          </li>
          <li>
            Information you may enter on our contact form regarding your inquiry
          </li>
          <li>
            From any emails you send to our contact email addresses, we will
            collect all the information you write in the email and any
            information in attachments.
          </li>
        </ul>
        <p>
          Please Note: Emails sent to us, including any attachments, may be
          monitored and used by us for reasons of security and for monitoring
          compliance with office policy. Email monitoring or blocking software
          may also be used. Please be aware that you have a responsibility to
          ensure that any email you send to us is within the bounds of the law.
        </p>
        <p>
          Where we provide links to websites of other organisations, this
          privacy notice does not cover how that organisation processes personal
          information. We encourage you to read the privacy notices on the other
          websites you visit.
        </p>
        <p>We do not use any cookies.</p>
      </section>

      <section className="my-3">
        <h2>5. When, Why and How we gain Consent (Legal Basis)</h2>
        <p>
          We will not collect any information about you without your explicit
          consent: We will ask your consent every time we collect personal
          information such as on our contact form.
        </p>
        <p>
          We will explain in clear and simple terms why we want to collect your
          information and what we will do with it before seeking your consent,
          so you are fully informed, and you will be given a clear and
          unambiguous option to opt in to any communications or services we
          might want to offer you.
        </p>
      </section>

      <section className="my-3">
        <h2>6. Why we Collect Personal Data</h2>
        <p>
          We collect information about you so we can respond to your enquiries
          and messages you send us via our contact forms and emails.
        </p>
        <p>
          If you use any of our services, then we will collect information about
          you to help us manage your account and so that we may perform the
          services you have requested, to get in touch if we need to tell you
          about something like a change to our policies or issues with a
          service.
        </p>
        <p>
          Once you have provided your information in relation to us providing
          any services, then we may retain it for business, regulatory, and
          legal purposes.
        </p>
      </section>

      <section className="my-3">
        <h2>
          7. Who Processes the Data We Collect (Who are the recipients of your
          data)
        </h2>
        <p>
          We will store and process your data following industry best practice
          and security.
        </p>
        <p>
          Where processing takes place by one of our trusted data processors, we
          ensure that our contracts with those 3rd parties contain the
          appropriate GDPR model clauses and that all our 3rd parties are also
          compliant with the GDPR. This affords your data the same protection
          away from our organisation as it does within it.
        </p>
        <p>
          The data we collect through our website and contact forms may be
          processed by one or more of the following:
        </p>
        <ul className='list-disc'>
          <li>Our trusted IT service provider</li>
          <li>Our trusted processing provider</li>
          <li>All companies that fall under VSN Group</li>
        </ul>
      </section>

      <section className="my-3">
        <h2>8. Where we might send your personal data (Geographically)</h2>
        <p>
          We do not send your data outside of the EU; we work on the servers of
          the EU.
        </p>
        <p>
          We process data within the EEA and countries deemed by the European
          Union as having adequate safeguards for protecting personal data.
          These countries are recognised by the EU as having suitable safeguards
          for the rights and freedoms of individuals and recourse processes by
          which data subjects can exercise their rights.
        </p>
        <p>We are currently not transferring data outside of the EU.</p>
      </section>

      <section className="my-3">
        <h2>9. How long do we keep your data</h2>
        <p>
          The data referenced above in section 4 - Data We collect is kept in
          line with our Company Retention Policy. You can request a copy by
          contacting our DPO:
        </p>
        <p>
          We will only retain your personal data for a limited period of time.
          This will depend on a number of factors, including:
        </p>
        <ul className='list-disc'>
          <li>
            Any positive activity on your record. Positive activity means that
            you open, read, interact with either the website or through any of
            the communication methods that you or we use to communicate with you
            or us.
          </li>
          <li>
            If we have recorded no positive activity from you after 6 months,
            your record will become dormant. If there is no positive activity
            from you within 12 months, we will delete your records.
          </li>
          <li>
            Whether we are in a legal or other type of dispute with each other
            or any third party.
          </li>
          <li>
            Whether we are asked by you or any regulatory authority to keep your
            personal data for a valid reason.
          </li>
        </ul>
      </section>

      <section className="my-3">
        <h2>10. Data Sources - where did you get my data</h2>
        <p>
          Any and all data in respect of - Data We Collect, is collected
          directly from you, the individual. We do not collect any of your
          personal data from any other sources. This includes any publicly
          accessible list and or data sources, whether in the public domain or
          if we have a legitimate interest to be able to access those sources
          legally.
        </p>
      </section>

      <section className="my-3">
        <h2>11. Do we have a statutory or legal right to hold this data?</h2>
        <p>
          We have no statutory or legal right to hold the data you have provided
          and detailed in respect of - Data We Collect.
        </p>
        <p>
          We rely on consent to collect and store this data and uphold all of
          the individual's rights and freedoms with respect to personal data
          given by consent.
        </p>
        <p>
          See section - Your Rights as an Individual in Respect of the Data We
          hold.
        </p>
      </section>

      <section className="my-3">
        <h2>
          12. Automated Decision Making, Profiling, and what that means for you
        </h2>
        <p>
          Any and all data in respect of - Data We Collect, is not subject to
          any automated decision making.
        </p>
        <p>
          We do not profile you using your data; any actions taken by us or our
          systems are as a direct result of explicit requests or consents you
          have chosen.
        </p>
        <p>
          There are no foreseeable consequences of any significance in respect
          of providing the data or being removed from the records, except that
          we will not be able to contact you.
        </p>
      </section>

      <section className="my-3">
        <h2>13. Your Rights as an Individual in Respect of the Data We hold</h2>
        <p>
          We respect the rights and freedoms of individuals and as such, we
          would like to make you aware of the following.
        </p>
        <p>You have the right to:</p>
        <ul className='list-disc'>
          <li>
            Right to access -- you can ask us whether we're processing your
            personal data, including where and for what purpose. You can also
            request an electronic copy of your personal data free of charge.
          </li>
          <li>
            Right to restrict processing -- in certain circumstances, you can
            ask us to restrict our use of your personal data.
          </li>
          <li>
            Right to rectification -- you can already ask us to correct
            inaccurate personal data we hold about you.
          </li>
          <li>
            Right to erasure (right to be forgotten) -- in certain
            circumstances, you can ask us to erase your personal data.
          </li>
          <li>
            Right to data portability -- you can ask us to provide you with a
            copy of your personal data in a commonly used electronic format so
            that you can transfer it to other businesses.
          </li>
          <li>
            Right to object to automated decision-making -- in certain
            circumstances, you can ask us not to make automated decisions about
            you based on your personal data that produce significant legal
            effects.
          </li>
          <li>
            Right to lodge a complaint -- you can lodge a complaint with us or
            your local data protection authority.
          </li>
        </ul>
      </section>
    </div>
  )
}

export default PrivacyPolicy
